import styled from 'styled-components'

const Stamp = styled.div`
	position: absolute;
	width: fit-content;
	padding: 4px 8px;
	top: 35%;
	left: 50%;
	transform: translate(-50%, -50%) rotate(-10deg);
	font-size: 100%;
	font-weight: bold;
	display: inline-block;
	text-transform: uppercase;
	border: 3px solid black;
	color: black;
	border-radius: 4px;
	${({ clickable }) => clickable && `cursor: pointer`}
`

export default Stamp
