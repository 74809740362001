import emoji from 'react-easy-emoji'
import { useDispatch } from 'react-redux'
import { actionImg } from '../../components/SessionBar'
import { skipTutorial } from '../../actions/actions'
import { FFFButton } from './fin/Buttons'

export default ({ value, unit }) => {
	const dispatch = useDispatch()
	return (
		<div css={`
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			background: var(--gradient);
			h1 {
				font-size: 50px;
				margin: 60px 0px 30px;
				color: black;
				letter-spacing: 0px;
			}
			> div {
				margin: 16px 0;
			}
			* {
				font-family: 'FuturaPT';
			}
			p {
				color: white;
				font-size: 1em;
				line-height: 18px;
				margin-bottom: 8px;
				padding: 0;
			}
			button {
				margin-top: 32px;
			}
		`}>
			<h1>
			SWING INTO ACTION
			</h1>
			<div>
				<p>You've finished you're first simulation, well done!</p>
				<p>
				You now know your footprint, estimated at {value} {unit},<br/> and you probably already have some ideas as to how to reduce it.
				</p>
				<p>
				Here is a list of possible actions to help you out::
				</p>
			</div>
			<div>
				<p>{emoji('✅')} select the ones that interest you</p>
				<p>
					{emoji('❌')} dismiss the ones that seem too ambitious or inappropriate
				</p>
				<p>
					<b>?</b> In order to be more specific, some actions will ask you some further questions.
				</p>
			</div>
			<FFFButton 
				colorType="fff_blue"
				onClick={() => dispatch(skipTutorial('actions'))}
				text="Start"
			/>
		</div>
	)
}
