import React, { useRef, useState } from 'react'
import { FFFButton } from '../sites/publicodes/fin/Buttons'

export default ({ text, url, title, color, label }) => navigator.share ?
		<FFFButton
			colorType="fff_blue"
			title="Cliquez pour partager le lien"
			fontSize="1.3em"
			fontWeight="normal"
			text={label}
			onClick={() =>
				navigator
					.share({ text, url, title, color, label })
					.then(() => console.log('Successful share'))
					.catch((error) => console.log('Error sharing', error))
			}
		/>
	 : 
		<DesktopShareButton {...{ label, color, text, url }} />
	

const copyToClipboardAsync = (str) => {
	if (navigator && navigator.clipboard && navigator.clipboard.writeText)
		return navigator.clipboard.writeText(str)
	return Promise.reject('The Clipboard API is not available.')
}

export const DesktopShareButton = ({ label, text, color, url }) => {
	const [copySuccess, setCopySuccess] = useState(false)

	const clipboardText = `${text}

${decodeURIComponent(url)}`

	return (
		<FFFButton
			title="Cliquez pour partager le lien"
			colorType="fff_blue"
			text={label}
			onClick={() => {
				copyToClipboardAsync(clipboardText).then(
					function () {
						/* clipboard successfully set */
						setCopySuccess(true)
					},
					function () {
						/* clipboard write failed */
						setCopySuccess(false)
					}
				)
			}}
		/>
	)
}

