import animate from 'Components/ui/animate'
import LogoADEME from 'Images/LogoADEME'
import { useContext } from 'react'
import emoji from 'react-easy-emoji'
import { Link } from 'react-router-dom'
import NewsBanner from '../../components/NewsBanner'
import { openmojiURL } from '../../components/SessionBar'
import Meta from '../../components/utils/Meta'
import { TrackerContext } from '../../components/utils/withTracker'
import DocumentationButton from './DocumentationButton'
import { useProfileData } from './Profil'
import styled from 'styled-components';

const Container = styled.div`
	width: 100%;
	padding: 16px 32px;
	height: calc(100vh - 130px);
	display: flex;
	flex-direction: column;
	background: linear-gradient(48deg, rgba(127,188,91,1) 0%, rgba(239,190,63,1) 60%, rgba(201,220,84,1) 100%);
	@media (max-width: 800px) {
		padding: 16px;
	}
`

export default () => {
	return (
		<Container>
			<Meta
				title="Green Runner Quiz"
				description='La FFF s’associe à Game Earth, pour permettre aux pratiquants amateurs de football de mesurer leur empreinte carbone individuelle et de s’informer sur les gestes à adopter pour réduire leur impact écologique grâce à l’outil « Mon Foot Carbone »'
			/>
			<Main />
			<Footer />
		</Container>

	)
}

const MainContainer = styled.section`
	width: 100%;
	display: flex;
	flex: 1;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 0;
	#illustration {
		width: 80%;
	}
	> div {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 32px;
		
		> div:first-of-type {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			p {
				font-size: 80px;
				font-weight: bold;
				line-height: 80px;
				color: black;
				margin: 0;
			}
		}
	}
	
	a {
		width: 128px;
		height: 128px;
		border-radius: 64px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: #7FBC5B;
		text-decoration: none;
	}
	a:hover p {
		color: black;
	}
	p {
		color: white;
	}
	p.main_font {
		margin: 0;
		font-weight: bold;
		font-size: 18px;
		line-height: 16px;
		letter-spacing: 1.08px;
	}
	p.secondary_font {
		margin: 4px 0 0 0;
		font-size: 9px;
		line-height: 12px;
		letter-spacing: 1.8px;
	}



	@media (max-width: 800px) {
		#illustration {
				width: 90%;
				margin-bottom: 16px;
			}
		> div {
			flex-direction: column;
			> div:first-of-type {
				p {
					font-size: 35px;
					line-height: 35px;
				}
			}
		}
		
	}

`

const Main = () => {
	const tracker = useContext(TrackerContext)
	return (
		<MainContainer>
			<img src="../../images/PERSONNAGES.png" alt="running people" id="illustration" />
			<div>
				<div>
					<p>GREEN RUNNER</p>
					<p>QUIZ</p>
				</div>
				<Link 
					to="/simulateur/bilan"
					onClick={() =>
						tracker.push(['trackEvent', 'NGC', 'Clic CTA accueil'])
					}
				>
					<p className='main_font'>RUN</p>
					<p className='main_font'>THE TEST</p>
					<p className='secondary_font'>START</p>
				</Link>
			</div>
		</MainContainer>
	)
}

const FooterContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	p {
		margin: 0;
		font-size: 8px;
		line-height: 9px;
		width: 60%;
		font-wieght: bold;
		letter-spacing: 1.2px;
		color: black
	}
	a {
		font-size: 12px;
		letter-spacing: 1.8px;
	}
	@media (max-width: 800px) {
		p {
			width: 100%;
		}
	}
`


const Footer = () => {
	return (
		<FooterContainer>
			<p>This variation developed by Game Earth for HOKA and the Mainova Frankfurt Marathon, is based freely on Mon Match Carbone´s official version developed in partnership with ABC and Datagir (ADEME).</p>
			{/* <Link to="/à-propos">About</Link> */}
		</FooterContainer>
	)
}