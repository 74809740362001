import { useEngine } from 'Components/utils/EngineContext'
import { utils } from 'publicodes'
import React from 'react'
import emoji from 'react-easy-emoji'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { correctValue, splitName } from '../../components/publicodesUtils'
import { buildEndURL } from '../../components/SessionBar'
import { lightenColor } from '../../components/utils/colors';
import { extractCategories } from 'Components/publicodesUtils';
import {
	objectifsSelector,
	situationSelector,
} from '../../selectors/simulationSelectors'
import HumanWeight, { DiffHumanWeight } from './HumanWeight'
import PetrolScore from './PetrolScore'

export default ({ actionMode = false, demoMode = false }) => {
	const objectif =
			actionMode || demoMode ? 'bilan' : useSelector(objectifsSelector)[0],
		// needed for this component to refresh on situation change :
		situation = useSelector(situationSelector),
		engine = useEngine(),
		rules = useSelector((state) => state.rules),
		evaluation = engine.evaluate(objectif),
		{ nodeValue: rawNodeValue, dottedName, unit, rawNode } = evaluation
	const actionChoices = useSelector((state) => state.actionChoices)
	const category = rules[splitName(dottedName)[0]],
		color = category && category.couleur

		const categories = extractCategories(rules, engine);
		const currentTotal = categories.reduce((total, category) => total + category.nodeValue, 0);
		const nodeValue = correctValue({ nodeValue: rawNodeValue, unit })


	return (
		<div
			css={`
				${!demoMode &&
				`
				@media (max-width: 800px) {
					margin: 0;
					position: fixed;
					bottom: 4rem;
					left: 0;
					z-index: 10;
					width: 100%;
				}`}

				color: var(--textColor);
				a {
					color: inherit;
				}
				text-align: center;
			`}
		>
			<div
				css={`
					display: flex;
					justify-content: space-evenly;
					align-items: center;
					height: 4rem;
					> a {
						height: 100%;
						text-decoration: none;
						padding: 0.4rem;
					}
				`}
			>
				<Link
					css={`
						flex-grow: 1;
						background-color: var(--yellow);
						height: 100%;
					`}
					to={demoMode ? '#' : buildEndURL(rules, engine)}
					title="Page de fin de simulation"
				>
					{!actionMode ? (
						<HumanWeight
							nodeValue={currentTotal}
							overrideValue={actionMode && actionTotal !== 0 && actionTotal}
						/>
					) : (
						<DiffHumanWeight
							{...{ nodeValue, engine, rules, actionChoices }}
						/>
					)}
				</Link>
				{/* <PetrolScore
					endURL={demoMode ? '#' : buildEndURL(rules, engine, 'pétrogaz')}
				/> */}
				{/* TODO désactivation de l'explication dans le contexte de l'ajout du pétrole : mieux vaut sûrement 
				mettre le lien d'explication sur l'écran vers lequel les deux métriques pointent. Probablement deux diapo 
				de la page fin.

				{!demoMode && !actionMode && (
					<DocumentationLink dottedName={dottedName} />
				)}
				*/}
			</div>
		</div>
	)
}

const DocumentationLink = ({ dottedName }) => (
	<div>
		<Link to={'/documentation/' + utils.encodeRuleName(dottedName)}>
			<span css="font-size: 140%" alt="Understand the calculation">
				{emoji('❔ ')}
			</span>
			<small
				css={`
					color: var(--textColor);
					@media (max-width: 800px) {
						display: none;
					}
				`}
			>
				Understand the calculation
			</small>
		</Link>
	</div>
)
